.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  /* @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  } */
}

.listingCard {
  width: 100%;
}
.paginationWrapper {
  margin: 0 0 100px;
}
.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}
.mapWrapper {
  width: 100%;
  height: 335px;
  margin-bottom: 70px;
  position: relative;

}

.map {
  /* width: 100vw;
  height: 100vh; */

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: absolute; */
    /* position: relative; */
    /* top: var(--topbarHeightDesktop);
    right: 0; */

    /* Fixed content needs width relative to viewport */
    /* width: 50vw; */
    /* height: calc(100vh - var(--topbarHeightDesktop)); */
  }

  @media (--viewportLarge) {
    /* width: 37.5vw; */
  }
}

.mapRoot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
